import './styles.less';
const {
  log: {
    getLogger
  }
} = svs.core;
const logger = getLogger('ombudsportalen-base:connectivity-check');
window.addEventListener('online', () => {
  logger.info('User became online.');
});
window.addEventListener('offline', () => {
  logger.info('User became offline.');
});